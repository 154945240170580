// @flow
import * as React from 'react'
import { laptop, mobile, tablet } from 'shared/media-queries'

import AecomLogo from 'imgs/logos/aecom-tishman.svg'
import BartonMallowLogo from 'imgs/logos/barton-malow.svg'
import CwDriverLogo from 'imgs/logos/cwdriverlogo.svg'
import ShawmutLogo from 'imgs/logos/shawmut.svg'
import WebcorLogo from 'imgs/logos/webcor.svg'
import HrcgLogo from 'imgs/logos/hunter-roberts.svg'

export const logos = {
	aecom: (
		<AecomLogo
			height="24px"
			width="249px"
			css={{
				[laptop]: { height: '17px', width: '177.5px' },
				[tablet]: { height: '13px', width: '132.5px' },
				[mobile]: { height: '13px', width: '134px' },
			}}
		/>
	),
	webcor: (
		<WebcorLogo
			height="32px"
			width="193px"
			css={{
				[laptop]: { height: '22.5px', width: '137.5px' },
				[tablet]: { height: '17px', width: '102.5px' },
				[mobile]: { height: '18px', width: '103px' },
			}}
		/>
	),
	shawmut: (
		<ShawmutLogo
			height="26px"
			width="138px"
			css={{
				[laptop]: { height: '18.5px', width: '98px' },
				[tablet]: { height: '14px', width: '73.5px' },
				[mobile]: { height: '18px', width: '96px' },
			}}
		/>
	),
	barton: (
		<BartonMallowLogo
			height="32px"
			width="68px"
			css={{
				[laptop]: { height: '22.5px', width: '48.5px' },
				[tablet]: { height: '17px', width: '36px' },
				[mobile]: { height: '20px', width: '42px' },
			}}
		/>
	),
	cwdriver: (
		<CwDriverLogo
			height="26px"
			width="135px"
			css={{
				[laptop]: { height: '18.5px', width: '96px' },
				[tablet]: { height: '14px', width: '72px' },
			}}
		/>
	),
	hrcg: (
		<HrcgLogo
			height="32px"
			width="100px"
			css={{
				[laptop]: { height: '22.5px', width: '71.5px' },
				[tablet]: { height: '17px', width: '53px' },
				[mobile]: { height: '20px', width: '62px' },
			}}
		/>
	),
}
