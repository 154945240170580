// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { Title } from 'views/components'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { gray65, lightBlue } from 'shared/colors'
import InteractiveInfo from 'views/components/interactive-info'
import { laptop } from 'shared/media-queries'
import { sections, images } from './constants'
import { breakpoints, tablet, mobile } from 'shared/media-queries'

type Props = {
	screenWidth: number,
	selectedDotColor: string,
	forwardedRef: React.Ref<'div'>,
}
type State = { openIndex: number }

class VendorManagement extends React.Component<Props, State> {
	state = {
		openIndex: 0,
	}

	render() {
		const { forwardedRef, screenWidth, selectedDotColor } = this.props
		const { openIndex } = this.state
		const openImage = images[openIndex]

		return (
			<Div
				ref={forwardedRef}
				minHeight="625px"
				css={{
					[tablet]: { textAlign: 'center' },
				}}
			>
				<Title wording="VENDOR MANAGEMENT" color={lightBlue} />
				<Spacer height="15px" />
				<H2
					css={{
						[tablet]: { maxWidth: '640px', margin: '0 auto' },
					}}
				>
					Make your qualification workflow quicker and easier.
				</H2>
				<Spacer height="20px" />
				<Div
					fontSize="24px"
					lineHeight="1.33"
					color={gray65}
					flex="0 0 500px"
					maxWidth="890px"
					css={{
						[mobile]: { fontSize: '16px' },
					}}
				>
					Discover and invite qualified subs from our growing network of 250,000
					vendors. Take on less risk and know that you’re working with the best
					subs.
				</Div>
				<Spacer height="50px" />
				<Div
					display="flex"
					css={{
						[tablet]: { display: 'block' },
					}}
				>
					<Div flex="1 1 0%">
						{screenWidth < breakpoints.tablet ? (
							<Img
								src={openImage.mobileSrc}
								srcSet={openImage.mobileSrcSet}
								width="100%"
							/>
						) : (
							<Img
								width="100%"
								maxHeight="476px"
								css={{
									[tablet]: { width: '85%' },
								}}
								src={openImage.src}
								srcSet={openImage.srcSet}
							/>
						)}
					</Div>
					<Div
						css={{
							width: '120px',
							[laptop]: { width: '100px' },
							[tablet]: { height: '50px' },
							[mobile]: { height: '20px' },
						}}
					/>
					<Div flex="0 0 400px">
						<InteractiveInfo
							sections={sections}
							onInteraction={i => this.setState({ openIndex: i })}
							screenWidth={screenWidth}
							selectedDotColor={selectedDotColor}
						/>
					</Div>
				</Div>
			</Div>
		)
	}
}
export default VendorManagement
