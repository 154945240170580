// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import Spacer from 'components/spacer'
import { lightBlue, gray65, gray60, brandBlue, navy } from 'shared/colors'
import HangingQuote from 'views/components/hanging-quote'

import Triangle from 'imgs/icons/triangle.svg'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'
import { Link } from 'gatsby'

type Props = {
	isOpen: boolean,
	openSelf: () => mixed,
	title: string,
	wording: string,
	thumbnail?: {},
	thumbnailText?: string,
	videoId?: string,
	linkText?: string,
	linkUrl?: string,
}

// TODO consider moving this to a shared component and just allowing additional content as JSX
const VideoWithThumbnail = ({ videoId, thumbnail, thumbnailText }) => (
	<Div
		display="flex"
		alignItems="center"
		paddingTop="15px"
		position="relative"
		cursor="pointer"
		css={{ ':hover': { textDecoration: 'underline' } }}
	>
		<WistiaVideoOverlay videoId={videoId} />
		<Img height="60px" {...thumbnail} />
		<Spacer width="10px" />
		<Div color={brandBlue} fontSize="16px" fontWeight="700" marginLeft="15px">
			<HangingQuote>{thumbnailText}</HangingQuote>
		</Div>
		<Spacer height="15px" />
	</Div>
)

const DesktopInfoSection = ({
	isOpen,
	openSelf,
	title,
	wording,
	thumbnail,
	thumbnailText,
	videoId,
	linkText,
	linkUrl,
}: Props) => (
	<Div>
		<Div
			display="flex"
			cursor="pointer"
			onClick={openSelf}
			css={{
				color: isOpen ? undefined : gray60,
				':hover': { color: isOpen ? undefined : brandBlue },
			}}
			position="relative"
		>
			<Div
				display="flex"
				transform={isOpen ? undefined : 'rotate(270deg)'}
				position="absolute"
				top="9px"
			>
				<Triangle fill={lightBlue} />
			</Div>
			<Spacer width="25px" />
			<Div letterSpacing="1px" fontWeight="600">
				{title}
			</Div>
		</Div>
		{isOpen && (
			<Div marginLeft="25px">
				<Spacer height="10px" />
				<Div fontSize="18px" color={gray65}>
					{wording}
				</Div>
				{thumbnail && thumbnailText && videoId && (
					<VideoWithThumbnail
						videoId={videoId}
						thumbnail={thumbnail}
						thumbnailText={thumbnailText}
					/>
				)}
				{linkText && linkUrl && (
					<Div fontSize="16px" fontWeight="700" color={navy} marginTop="5px">
						<Link to={linkUrl} css={{ color: brandBlue }}>
							{linkText} &raquo;
						</Link>
					</Div>
				)}
			</Div>
		)}
	</Div>
)

export default DesktopInfoSection
