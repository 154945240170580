// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import riskMitigation from './img/risk-mitigation.png'
import riskMitigation2x from './img/risk-mitigation@2x.png'
import riskMitigation3x from './img/risk-mitigation@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth > breakpoints.laptop) {
		return '550px'
	} else if (screenWidth > breakpoints.mobile) {
		return '380px'
	}
	return '290px'
}

const riskMitigationImage = (
	<Img
		width="100%"
		src={riskMitigation}
		srcSet={`
					${riskMitigation2x} 2x,
					${riskMitigation3x} 3x,
				`}
		css={{
			[tablet]: { height: 'auto' },
		}}
	/>
)

const RiskMitigation = ({
	screenWidth,
	forwardedRef,
}: {
	screenWidth: number,
	forwardedRef: React.Ref<'div'>,
}) => (
	<Div ref={forwardedRef}>
		<InfoAndImage
			screenWidth={screenWidth}
			imageOnRight
			image={riskMitigationImage}
			title="RISK MITIGATION"
			subtitle="Implement smarter risk analysis practices that protect your bottom line."
			info="Automated risk mitigation recommendations enable teams to identify and reduce risk early in the preconstruction process."
			centered={screenWidth < breakpoints.tablet}
			wordingWidth={getWordingWidth(screenWidth)}
			bulletItems={[
				'Receive mitigation recommendations tailored to your specific subs and projects',
				'Customize review workflows and collaborate across teams',
				'Create and edit risk mitigation plans, and track approvals',
			]}
		/>
	</Div>
)

export default RiskMitigation
