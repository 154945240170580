// @flow
import * as React from 'react'
import QuoteAndImage from 'views/components/carousel/quote-and-image'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'
import image from './img/neal-morton.jpg'
import image2x from './img/neal-morton@2x.jpg'
import image3x from './img/neal-morton@3x.jpg'

const quote = {
	quote: `We have taken out a number of unnecessary steps within our process because the right people are making the right recommendation to eliminate the risk. That's eliminated close to four weeks out of the process.`,
	name: 'Neal Morton',
	title: 'VP, Barton Malow Company',
	image: {
		src: image,
		srcSet: `${image2x} 2x, ${image3x} 3x`,
	},
	video: {
		length: '1:33',
		element: <WistiaVideoOverlay videoId="qcf8jcg372" />,
	},
}

const QuoteSection = () => (
	<div>
		<QuoteAndImage {...quote} inlineLogo singleQuote />
	</div>
)

export default QuoteSection
