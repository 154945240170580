// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { gray02, gray65 } from 'shared/colors'
import Spacer from 'components/spacer'
import { breakpoints, laptop, mobile, tablet } from 'shared/media-queries'
import { logos as gcLogos } from './gcs'

const defaultLogos = [
	gcLogos.aecom,
	gcLogos.webcor,
	gcLogos.shawmut,
	gcLogos.barton,
	gcLogos.cwdriver,
	gcLogos.hrcg,
]

const LogoStrip = ({
	screenWidth,
	logos = defaultLogos,
	wording,
}: {
	screenWidth: number,
	logos?: Array<React.Node>,
	wording?: string,
}) => {
	const tabletSize = screenWidth < breakpoints.laptop
	const mobileSize = screenWidth < 768
	let logosToDisplay = logos
	if (mobileSize) {
		logos.splice(4, 1)
		logosToDisplay = logos
	} else if (tabletSize) {
		logosToDisplay = logos.slice(0, 6)
	}

	return (
		<Div padding="35px 0 15px" backgroundColor={gray02} textAlign="center">
			{!!wording && (
				<>
					<Div
						fontSize="20px"
						fontWeight="600"
						color={gray65}
						css={{
							[tablet]: { fontSize: '14px' },
							[mobile]: { width: '261px', margin: '0 auto' },
						}}
					>
						{wording}
					</Div>
					<Spacer height="30px" />
				</>
			)}
			<Div
				display="flex"
				alignItems="center"
				justifyContent="center"
				css={{
					[mobile]: {
						justifyContent: 'center',
						flexFlow: 'wrap',
					},
				}}
			>
				{logosToDisplay.map((logo, i) => (
					<Div
						key={i}
						marginBottom="20px"
						marginLeft="30px"
						marginRight="30px"
						justifyContent="center"
						css={{
							[laptop]: { marginLeft: '20px', marginRight: '20px' },
							[tablet]: { marginLeft: '15px', marginRight: '15px' },
						}}
					>
						{logo}
					</Div>
				))}
			</Div>
		</Div>
	)
}

export default LogoStrip
