// @flow
import tradetapp from './img/m-5-image-1-1440.png'
import tradetapp2x from './img/m-5-image-1-1440@2x.png'
import tradetapp3x from './img/m-5-image-1-1440@3x.png'
import tradetappMobile from './img/m-5-1-320.png'
import tradetappMobile2x from './img/m-5-1-320@2x.png'
import tradetappMobile3x from './img/m-5-1-320@3x.png'

import automate from './img/m-5-image-2-1440.png'
import automate2x from './img/m-5-image-2-1440@2x.png'
import automate3x from './img/m-5-image-2-1440@3x.png'
import automateMobile from './img/m-5-2-320.png'
import automateMobile2x from './img/m-5-2-320@2x.png'
import automateMobile3x from './img/m-5-2-320@3x.png'

import increase from './img/m-5-image-3-1440.png'
import increase2x from './img/m-5-image-3-1440@2x.png'
import increase3x from './img/m-5-image-3-1440@3x.png'
import increaseMobile from './img/m-5-3-320.png'
import increaseMobile2x from './img/m-5-3-320@2x.png'
import increaseMobile3x from './img/m-5-3-320@3x.png'

export const sections = [
	{
		title: 'TRADETAPP + BUILDINGCONNECTED',
		wording:
			'TradeTapp is the only system that fully integrates with a bidding platform, giving estimators risk analysis throughout the process.',
		linkText: 'Learn more',
		linkUrl: '/bc-tradetapp/',
	},
	{
		title: 'AUTOMATE NOTIFICATIONS AND REVIEWS',
		wording:
			'TradeTapp sends automated notifications to foster faster reviews and collaboration between risk teams.',
	},
	{
		title: 'INCREASE TRANSPARENCY',
		wording:
			'Centralize reviews, communicate qualification statuses or assign limits for annual use.',
	},
]

export const images = [
	{
		src: tradetapp,
		srcSet: `
			${tradetapp2x} 2x,
			${tradetapp3x} 3x,
		`,
		mobileSrc: tradetappMobile,
		mobileSrcSet: `
		${tradetappMobile2x} 2x,
		${tradetappMobile3x} 3x,
	`,
	},
	{
		src: automate,
		srcSet: `
			${automate2x} 2x,
			${automate3x} 3x,
		`,
		mobileSrc: automateMobile,
		mobileSrcSet: `
		${automateMobile2x} 2x,
		${automateMobile3x} 3x,
	`,
	},
	{
		src: increase,
		srcSet: `
			${increase2x} 2x,
			${increase3x} 3x,
		`,
		mobileSrc: increaseMobile,
		mobileSrcSet: `
		${increaseMobile2x} 2x,
		${increaseMobile3x} 3x,
	`,
	},
]
