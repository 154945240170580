// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import { Title } from 'views/components'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import InteractiveInfo from 'views/components/interactive-info'
import { breakpoints, laptop, tablet, mobile } from 'shared/media-queries'
import { sections, images } from './constants'
import { lightBlue } from 'shared/colors'

type Props = { screenWidth: number, selectedDotColor: string }
type State = {
	openIndex: number,
}

const Header = props => (
	<Div {...props}>
		<Title wording="INTERNAL COLLABORATION" color={lightBlue} />
		<Spacer height="15px" />
		<H2
			css={{
				[laptop]: { maxWidth: '550px', textAlign: 'left' },
				[tablet]: { margin: '0 auto', textAlign: 'center' },
			}}
		>
			Qualify subs with input from across your organization.
		</H2>
		<Div
			height="30px"
			css={{
				[tablet]: { height: '50px' },
				[mobile]: { height: '20px' },
			}}
		/>
	</Div>
)

class InternalCollaboration extends React.Component<Props, State> {
	state = {
		openIndex: 0,
	}

	render() {
		const { screenWidth, selectedDotColor } = this.props
		const { openIndex } = this.state
		const openImage = images[openIndex]

		return screenWidth >= breakpoints.laptop ? (
			<Div>
				<Header
					css={{
						display: 'none',
						[tablet]: { display: 'block' },
					}}
				/>
				<Div
					display="flex"
					justifyContent="space-between"
					css={{
						[laptop]: { alignItems: 'none' },
					}}
				>
					<Div flex="0 0 395px">
						<Header
							css={{
								[tablet]: { display: 'none' },
							}}
						/>
						<Div>
							<InteractiveInfo
								sections={sections}
								onInteraction={i => this.setState({ openIndex: i })}
								screenWidth={screenWidth}
								selectedDotColor={selectedDotColor}
							/>
						</Div>
					</Div>
					<Div
						minWidth="0px"
						marginLeft="40px"
						marginTop="50px"
						css={{
							[laptop]: { marginTop: '90px' },
						}}
					>
						<Img src={openImage.src} srcSet={openImage.srcSet} width="100%" />
					</Div>
				</Div>
			</Div>
		) : (
			<Div textAlign="center" alignItems="center">
				<Header />
				{screenWidth < breakpoints.tablet ? (
					<Img src={openImage.mobileSrc} srcSet={openImage.mobileSrcSet} />
				) : (
					<Img
						src={openImage.src}
						srcSet={openImage.srcSet}
						width="100%"
						css={{ [tablet]: { width: '85%' } }}
					/>
				)}
				<Div
					height="50px"
					css={{
						[mobile]: { height: '20px' },
					}}
				/>
				<Div flex="0 0 400px">
					<InteractiveInfo
						sections={sections}
						onInteraction={i => this.setState({ openIndex: i })}
						screenWidth={screenWidth}
						selectedDotColor={selectedDotColor}
					/>
				</Div>
			</Div>
		)
	}
}

export default InternalCollaboration
