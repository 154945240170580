// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Button, Hero } from 'views/components'
import H1 from 'components/h1'
import { tablet, mobile } from 'shared/media-queries'
import heroVideoWebm from './img/tradetapp-hero-bg.webm'
import heroVideoMp4 from './img/tradetapp-hero-bg.mp4'
import { showRequestDemoModal } from 'components/modal-manager'

import { lightBlue, navy } from 'shared/colors'

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		Meet TradeTapp
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		The most powerful qualification and risk mitigation platform used by the
		world’s best builders.
	</Div>
)

const Header = () => (
	<Hero
		backgroundVideo={{
			webm: heroVideoWebm,
			mp4: heroVideoMp4,
		}}
		heading={heading}
		subHeading={subHeading}
		CTAs={
			<Div
				flex="1 1 0%"
				display="flex"
				css={{
					[tablet]: { justifyContent: 'center' },
					[mobile]: { flexDirection: 'column' },
				}}
			>
				<Button
					label="Get a Demo"
					backgroundColor={lightBlue}
					onClick={() => {
						showRequestDemoModal()
					}}
					padding="0px 40px"
					cursor="pointer"
					css={{ [mobile]: { padding: '0 15px' } }}
				/>
				<Div flex="0 0 15px" />
				<Button
					label="Log in to TradeTapp"
					backgroundColor={navy}
					borderRadius="2px"
					border={`1px solid ${lightBlue}`}
					padding="0px 20px"
					cursor="pointer"
					onClick={() => {
						window.open('https://app.tradetapp.com/#/users/login', '_blank')
					}}
				/>
			</Div>
		}
	/>
)

export default Header
