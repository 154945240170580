// @flow
import * as React from 'react'
import { Img, Div } from 'glamorous'

import screenshot3651 from './3651.png'
import screenshot2434 from './2434.png'
import screenshot1217 from './1217.png'

const MainInfoScreenshot = () => (
	<Div position="relative">
		<Img
			width="100%"
			src={screenshot3651}
			srcSet={`
				${screenshot3651} 3651w,
				${screenshot2434} 2434w,
				${screenshot1217} 1217w,
			`}
		/>
	</Div>
)

export default MainInfoScreenshot
