// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { gray20 } from 'shared/colors'
import DesktopInfoSection from './desktop-info-section'
import MobileInfoSections from './mobile-info-sections'

import { breakpoints } from 'shared/media-queries'

type Props = {
	sections: Array<{
		title: string,
		thumbnail?: {},
		thumbnailText?: string,
		wording: string,
		videoId?: string,
		linkText?: string,
		linkUrl?: string,
	}>,
	onInteraction: number => mixed,
	screenWidth: number,
	selectedDotColor: string,
}

type State = {
	openIndex: number,
}

class InteractiveInfo extends React.Component<Props, State> {
	state = {
		openIndex: 0,
	}

	render() {
		const { sections, screenWidth, selectedDotColor } = this.props
		const { openIndex } = this.state

		return screenWidth >= breakpoints.laptop ? (
			<Div>
				{sections.map((section, i) => (
					<Div key={section.title}>
						<DesktopInfoSection
							isOpen={i === this.state.openIndex}
							{...section}
							openSelf={this.openSection(i)}
						/>
						{i < sections.length - 1 && (
							<Div borderTop={`1px solid ${gray20}`} margin="10px 0" />
						)}
					</Div>
				))}
			</Div>
		) : (
			<MobileInfoSections
				openSection={sections[openIndex]}
				changeSection={this.changeSection}
				openIndex={openIndex}
				totalLength={sections.length}
				screenWidth={screenWidth}
				selectedDotColor={selectedDotColor}
			/>
		)
	}

	openSection = (i: number) => () => {
		const { onInteraction } = this.props
		this.setState({ openIndex: i })
		onInteraction && onInteraction(i)
	}

	changeSection = (delta: -1 | 1) => {
		const { onInteraction } = this.props

		this.setState((state, props) => {
			let newIndex = state.openIndex + delta
			if (newIndex > props.sections.length - 1) {
				newIndex = 0
			} else if (newIndex < 0) {
				newIndex = props.sections.length - 1
			}

			onInteraction && onInteraction(newIndex)

			return {
				openIndex: newIndex,
			}
		})
	}
}

export default InteractiveInfo
