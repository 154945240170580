// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { mobile } from 'shared/media-queries'

import Heading from './heading'
import Screenshot from './screenshot'

type Props = {
	forwardedRef: React.Ref<'div'>,
}

const RiskAnalysis = ({ forwardedRef }: Props) => (
	<Div ref={forwardedRef}>
		<Heading />
		<Div
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
		<Screenshot />
	</Div>
)

export default RiskAnalysis
