// @flow
import qualify from './img/m-4-image-1-1440.png'
import qualify2x from './img/m-4-image-1-1440@2x.png'
import qualify3x from './img/m-4-image-1-1440@3x.png'
import qualifyMobile from './img/m-4-1-320.png'
import qualifyMobile2x from './img/m-4-1-320@2x.png'
import qualifyMobile3x from './img/m-4-1-320@3x.png'

import complete from './img/m-4-image-2-1440.png'
import complete2x from './img/m-4-image-2-1440@2x.png'
import complete3x from './img/m-4-image-2-1440@3x.png'
import completeMobile from './img/m-4-2-320.png'
import completeMobile2x from './img/m-4-2-320@2x.png'
import completeMobile3x from './img/m-4-2-320@3x.png'

import create from './img/m-4-image-3-1440.png'
import create2x from './img/m-4-image-3-1440@2x.png'
import create3x from './img/m-4-image-3-1440@3x.png'
import createMobile from './img/m-4-3-320.png'
import createMobile2x from './img/m-4-3-320@2x.png'
import createMobile3x from './img/m-4-3-320@3x.png'

export const sections = [
	{
		title: 'QUALIFY WITH VERIFIED DATA',
		wording:
			'TradeTapp sends subs automated follow-ups, reminders and renewals to collect up-to-date info, and then verify its accuracy. ',
	},
	{
		title: 'COMPLETE VENDOR PROFILES',
		wording:
			'See risk from every angle. Leverage our API to integrate with systems like your ERP to monitor subcontractor backlog internally.',
	},
	{
		title: 'CREATE QUESTIONNAIRES FOR EVERY SITUATION',
		wording:
			'One-size doesn’t always fit all. Use TradeTapp’s standard questionnaires or create a custom version for every type of vendor or sub.',
	},
]

export const images = [
	{
		src: qualify,
		srcSet: `
			${qualify2x} 2x,
			${qualify3x} 3x,
		`,
		mobileSrc: qualifyMobile,
		mobileSrcSet: `
		${qualifyMobile2x} 2x,
		${qualifyMobile3x} 3x,
	`,
	},
	{
		src: complete,
		srcSet: `
			${complete2x} 2x,
			${complete3x} 3x,
		`,
		mobileSrc: completeMobile,
		mobileSrcSet: `
		${completeMobile2x} 2x,
		${completeMobile3x} 3x,
	`,
	},
	{
		src: create,
		srcSet: `
			${create2x} 2x,
			${create3x} 3x,
		`,
		mobileSrc: createMobile,
		mobileSrcSet: `
		${createMobile2x} 2x,
		${createMobile3x} 3x,
	`,
	},
]
