// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import Dot from './dot.svg'
import { tablet, mobile } from 'shared/media-queries'

const DotSection = ({
	totalLength,
	openIndex,
	selectedColor,
}: {
	totalLength: number,
	openIndex: number,
	selectedColor: string,
}) => (
	<Div
		display="flex"
		justifyContent="space-around"
		flex={`0 1 ${totalLength * 40}px`}
		margin="0 10px"
		css={{
			[tablet]: { justifyContent: 'center' },
		}}
	>
		{Array(totalLength)
			.fill(null)
			.map((section, i) => (
				<Dot
					key={i}
					css={{
						[tablet]: { margin: '0 30px' },
						[mobile]: { margin: '0 20px' },
					}}
					fill={i === openIndex ? selectedColor : '#D7DCE0'}
				/>
			))}
	</Div>
)

export default DotSection
