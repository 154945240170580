// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import ArrowButton from './arrow-button.svg'
import DotSection from './dot-section'

const DotsWithArrowsControl = ({
	changeSection,
	totalLength,
	openIndex,
	selectedColor,
}: {
	changeSection: (-1 | 1) => mixed,
	totalLength: number,
	openIndex: number,
	selectedColor: string,
}) => (
	<Div display="flex" justifyContent="center" alignItems="center">
		<Div
			onClick={() => changeSection(-1)}
			flex="0 0 auto"
			justifyContent="center"
			display="flex"
		>
			<ArrowButton display="block" height="50px" />
		</Div>
		<DotSection
			totalLength={totalLength}
			openIndex={openIndex}
			selectedColor={selectedColor}
		/>
		<Div
			transform="rotate(180deg)"
			onClick={() => changeSection(1)}
			flex="0 0 auto"
			justifyContent="center"
			display="flex"
		>
			<ArrowButton display="block" height="50px" />
		</Div>
	</Div>
)

export default DotsWithArrowsControl
