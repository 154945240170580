// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Title } from 'views/components'
import { gray80, lightBlue } from 'shared/colors'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { laptop, tablet, mobile } from 'shared/media-queries'

const Heading = () => (
	<Div display="flex" flexDirection="column" alignItems="center">
		<Title wording="RISK ANALYSIS" color={lightBlue} />
		<Spacer height="20px" />
		<H2
			textAlign="center"
			additionalCss={{
				[tablet]: { fontSize: '36px' },
			}}
		>
			Streamline subcontractor qualification from start to finish.
		</H2>
		<Spacer height="20px" />
		<Div
			fontSize="24px"
			lineHeight="1.33"
			width="75%"
			textAlign="center"
			color={gray80}
			css={{
				width: '82%',
				[laptop]: { width: '100%' },
				[mobile]: { fontSize: '16px', lineHeight: '1.25' },
			}}
		>
			Significantly decrease the time it takes to analyze subcontractor risk,
			annually or for a specific project. Advanced risk profiles enable a
			streamlined process and offer financial benchmarking, key metric
			calculations, capacity recommendations and safety performance history.
		</Div>
	</Div>
)

export default Heading
