// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import Spacer from 'components/spacer'
import { gray65 } from 'shared/colors'
import ArrowButton from 'views/components/carousel/arrow-button.svg'
import DotsWithArrowsControl from 'views/components/carousel/dots-with-arrows-control'
import DotSection from 'views/components/carousel/dot-section'
import { breakpoints } from 'shared/media-queries'

type Props = {
	openSection: {
		title: string,
		wording: string,
	},
	changeSection: (-1 | 1) => mixed,
	openIndex: number,
	totalLength: number,
	screenWidth: number,
	selectedDotColor: string,
}

const WordingWithArrowsSection = ({ changeSection, openSection }) => (
	<Div display="flex" alignItems="center" height="75px">
		<Div onClick={() => changeSection(-1)}>
			<ArrowButton display="block" />
		</Div>
		<Spacer width="30px" />
		<WordingSection openSection={openSection} />
		<Spacer width="30px" />
		<Div transform="rotate(180deg)" onClick={() => changeSection(1)}>
			<ArrowButton display="block" />
		</Div>
	</Div>
)
const WordingSection = ({ openSection }) => (
	<Div flex="1 1 0%" color={gray65}>
		{openSection.wording}
	</Div>
)

const MobileInfoSections = ({
	openSection,
	changeSection,
	openIndex,
	totalLength,
	screenWidth,
	selectedDotColor,
}: Props) => (
	<Div>
		<Div letterSpacing="1px" fontWeight="600" fontSize="20px">
			{openSection.title}
		</Div>
		<Spacer height="10px" />
		{screenWidth >= breakpoints.tablet ? (
			<WordingWithArrowsSection
				changeSection={changeSection}
				openSection={openSection}
			/>
		) : (
			<WordingSection openSection={openSection} />
		)}
		<Spacer height="40px" />
		{screenWidth >= breakpoints.tablet ? (
			<DotSection
				totalLength={totalLength}
				openIndex={openIndex}
				selectedColor={selectedDotColor}
			/>
		) : (
			<DotsWithArrowsControl
				changeSection={changeSection}
				totalLength={totalLength}
				openIndex={openIndex}
				selectedColor={selectedDotColor}
			/>
		)}
	</Div>
)

export default MobileInfoSections
