// @flow
import { useState, useEffect } from 'react'
import _ from 'lodash'
import qs from 'query-string'
import ReactDOM from 'react-dom'

import isBrowser from 'shared/is-browser'

const scrollToSection = ({ ref, offset }) => {
	const node = ReactDOM.findDOMNode(ref.current)
	if (node instanceof HTMLElement) {
		const top = node.offsetTop + offset
		window.scrollTo({
			top,
			behavior: 'smooth',
		})
	}
}

const useScrollTo = ({
	keysToRefs,
	offset = -85,
	ignoreLastScrolledTo = false,
}: {
	keysToRefs: {},
	offset?: number,
	ignoreLastScrolledTo: boolean,
}) => {
	const [lastScrolledTo, setLastScrolledTo] = useState(null)

	useEffect(() => {
		if (isBrowser) {
			const { search } = window.location
			const { scrollTo } = qs.parse(search)

			if (scrollTo && typeof scrollTo === 'string') {
				const ref = keysToRefs[scrollTo]

				// Scroll to the section if we have the ref available
				if (ref && scrollTo !== lastScrolledTo) {
					_.defer(() => {
						scrollToSection({ ref, offset })

						// Wrapped in this if check so we can opt out of this functionality.
						// It was added because when an LP uses `useScreenWidth` it causes
						// page rerenders on screen width changes, which would cause the
						// page to scroll back to the last requested scroll to section. If
						// you make an LP without the use of that hook (which we should do
						// from now on) you can opt out of this in order to allow the scroll
						// to to work when clicking on the same one multiple times in a row
						if (!ignoreLastScrolledTo) {
							setLastScrolledTo(scrollTo)
						}
					})
				}
			}
		}
	}, [keysToRefs, offset, lastScrolledTo, ignoreLastScrolledTo])
}

export default useScrollTo
