// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import Spacer from 'components/spacer'
import HangingQuote from 'views/components/hanging-quote'
import { gray65 } from 'shared/colors'
import PlayIcon from 'imgs/icons/play-button.svg'
import { laptop, tablet, mobile } from 'shared/media-queries'

type Props = {
	quote: string,
	name: string,
	title: string,
	image: {
		src: string,
		srcSet: string,
	},
	video?: {
		length: string,
		element: React.Node,
	},
	logo?: {},
	inlineLogo?: boolean,
	quoteSpacerHeight?: string,
	singleQuote?: boolean,
}

const QuoteAndImage = ({
	quote,
	name,
	title,
	image,
	video,
	logo,
	inlineLogo,
	singleQuote,
}: Props) => (
	<Div
		display="flex"
		flex="1 1 0%"
		maxWidth="1000px"
		margin="0 auto"
		boxShadow="-2px 2px 10px 5px rgba(0, 0, 0, 0.1)"
		border="solid 1px rgba(151, 151, 151, 0.25)"
		css={{
			[laptop]: { maxWidth: singleQuote ? undefined : '730px' },
			[tablet]: {
				flexDirection: 'column',
				maxWidth: '500px',
				margin: '0 auto',
			},
		}}
	>
		<Div
			flex="1 1 0%"
			css={{
				[tablet]: { order: 2 },
				[mobile]: { minHeight: '200px' },
			}}
		>
			<Div
				css={{
					padding: '40px 40px 0',
					[laptop]: { padding: singleQuote ? '40px' : '20px 20px 0' },
					[tablet]: { padding: '20px' },
				}}
			>
				<Div
					fontSize="24px"
					lineHeight="1.25"
					css={{
						[laptop]: { fontSize: singleQuote ? undefined : '20px' },
						[tablet]: { fontSize: '24px' },
						[mobile]: { fontSize: '18px' },
					}}
				>
					<HangingQuote>{quote}</HangingQuote>
				</Div>
				<Div
					height="25px"
					css={{
						[tablet]: { height: '15px' },
					}}
				/>
				<Div
					fontSize="18px"
					color={gray65}
					lineHeight="1.3"
					css={{
						[mobile]: { fontSize: '16px', lineHeight: '1.25' },
					}}
				>
					<b>{name}</b>
					<div>{title}</div>
					{inlineLogo ? <Img {...logo} marginTop="15px" /> : null}
				</Div>
			</Div>
		</Div>
		<Div position="relative" cursor="pointer" minWidth="0px" flex="1 1 0%">
			<Div height="100%" width="100%" overflow="hidden">
				<Img
					{...image}
					height="100%"
					width="100%"
					display="block"
					objectFit="cover"
				/>
			</Div>
			{video && (
				<Div
					position="absolute"
					bottom="20px"
					left="30px"
					display="flex"
					alignItems="center"
				>
					<PlayIcon width="30px" height="30px" fill="white" />
					<Spacer width="7px" />
					<Div fontSize="18px" fontWeight="700" color="white" lineHeight="1">
						Play Video ({video.length})
					</Div>
				</Div>
			)}
			{video && video.element}
		</Div>
	</Div>
)

export default QuoteAndImage
