// @flow
import * as React from 'react'
import { useRef } from 'react'

import Layout from 'components/layout'
import {
	SectionSpacer,
	SectionBreak,
	ContentWrapper,
	Footer,
} from 'views/components'
import useScreenWidth from 'hooks/use-screen-width'
import Header from './header'
import RiskAnalysis from './risk-analysis'
import RiskMitigation from './risk-mitigation'
import VendorManagement from './vendor-management'
import InternalCollaboration from './internal-collaboration'
import QuoteSection from './quote'
import ResourceHub from './resource-hub'
import LogoStrip from './logo-strip'
import { lightBlue } from 'shared/colors'
import useScrollTo from 'hooks/use-scroll-to'

const TradeTappLP = () => {
	const analysisRef = useRef(null)
	const managementRef = useRef(null)
	const mitigationRef = useRef(null)

	useScrollTo({
		keysToRefs: {
			analysis: analysisRef,
			mitigation: mitigationRef,
			management: managementRef,
		},
	})

	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="TradeTapp - Risk Mitigation Software | BuildingConnected"
			description="Meet TradeTapp, the most powerful subcontractor qualification & risk mitigation platform used by the world’s best builders."
		>
			<Header screenWidth={screenWidth} />
			<LogoStrip screenWidth={screenWidth} />
			<SectionSpacer />
			<ContentWrapper>
				<RiskAnalysis screenWidth={screenWidth} forwardedRef={analysisRef} />
				<SectionBreak />
				<RiskMitigation
					screenWidth={screenWidth}
					forwardedRef={mitigationRef}
				/>
				<SectionBreak />
				<VendorManagement
					screenWidth={screenWidth}
					selectedDotColor={lightBlue}
					forwardedRef={managementRef}
				/>
				<SectionBreak />
				<InternalCollaboration
					screenWidth={screenWidth}
					selectedDotColor={lightBlue}
				/>
				<SectionBreak />
				<QuoteSection />
				<SectionBreak />
				<ResourceHub />
				<SectionSpacer />
			</ContentWrapper>
			<Footer
				wording="Start reducing your risk today with TradeTapp."
				demoButtonWording="Get a demo"
				demoButtonPadding="80px !important"
				ctaColor={lightBlue}
			/>
		</Layout>
	)
}

export default TradeTappLP
